import clsx from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import css from './styles.module.scss';

interface ContactStripeProps {
  message: string;
}

export function ContactStripe({ message }: ContactStripeProps) {
  return (
    <div className={css['in-contactStripe']}>
      <PepitaIcon
        name="chat--active"
        customClass={clsx(css['in-contactStripe__icon'], 'nd-icon--sm')}
      />
      {message}
    </div>
  );
}
