import { useEffect } from 'react';
import { useIsOnClient } from '@indomita-react/custom-hooks';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';
import { useAtomValue, useSetAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import type { AgencyPageData } from 'src/repository/agency/types/formatted-data-types';

import { bannersDataAtom } from 'src/atoms/bannersDataAtom';
import { agencyPageDataAtom } from './atoms';
import { realEstateListCountAtom } from './atoms/realEstateListCountAtom';

import { Breadcrumb } from 'src/components/Breadcrumb';
import { BreadcrumbsLite } from 'src/components/BreadcrumbsLite';
import { FooterColumnCompact } from 'src/components/Footer/FooterColumnCompact';
import { FormBar } from 'src/components/FormBar';
import { Header } from 'src/components/Header';
import { UnicreditUTag } from 'src/components/UnicreditUTag';
import { AgencyCover } from './components/AgencyCover';
import { AgencyFormBar } from './components/AgencyFormBar';
import { AgencyRealEstateList } from './components/AgencyRealEstateList';
import { AgencyRealEstateSearchMap } from './components/AgencyRealEstateSearchMap';
import { AgencyStickyHeader } from './components/AgencyStickyHeader';
import { AgentCover } from './components/AgentCover';
import { AgentStickyHeader } from './components/AgentStickyHeader';
import { Banner } from './components/Banner';
import { FranchisingCover } from './components/FranchisingCover';
import { FranchisingStickyHeader } from './components/FranchisingStickyHeader';
import { RealEstateResultsList } from './components/RealEstateResultsList';

import { isFeatureEnabled } from 'src/config/features-toggle';

import {
  layoutModeAtom,
  SearchLayout,
  showMapAtom,
} from 'src/entities/search/components/SearchLayout';

import { useAgencyPageViewTrack } from './hooks/useAgencyPageViewTrack';
import { useAgencySeoData } from './hooks/useAgencySeoData';

import { ContactSourcePage, contactSourcePageAtom } from 'src/libs/contact';

import { updateGA4AgencyTrackingData } from 'src/tracking/ga/utils/store';
import { setSegmentAgencyTrackingData } from 'src/tracking/segment/utils';
import { setEntryPoint } from 'src/tracking/utils';
import { baseAgencyInfo, getEntryPoint } from './tracking/utils';

import type { BannerConfig } from 'src/types/banner';

import {
  PageFragmentPrefix,
  removePageFragmentWithPrefix,
} from 'src/utils/pageFragment';

import { useUpdateQueryParams } from 'src/views/Agency/hooks/useUpdateQueryParams';

import css from './styles.module.scss';

enum Styles {
  breadcrumbs = 'ag-agency__breadcrumbs',
  bottomBanner = 'ag-agency__bottomBanner',
  footer = 'ag-agency__footer',
  map = 'ag-agency__map',
}

interface AgencyProps {
  data: AgencyPageData;
  referrer: string;
  jotaiAtoms: {
    realEstateListCount: number;
    bannersDataAtom: BannerConfig;
  };
}

export function Agency({ data, referrer, jotaiAtoms }: AgencyProps) {
  useHydrateAtoms([
    [realEstateListCountAtom, jotaiAtoms.realEstateListCount],
    [bannersDataAtom, jotaiAtoms.bannersDataAtom],
  ]);
  useAgencyPageViewTrack(referrer);
  const setAgencyPageData = useSetAtom(agencyPageDataAtom);

  const showMap = useAtomValue(showMapAtom);
  const layoutMode = useAtomValue(layoutModeAtom);
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const { name, dataType } = data;

  const isOnClient = useIsOnClient();

  const isAgency = dataType === 'agency' || dataType === 'court';
  const isAgent = dataType === 'agent';
  const isFranchising = dataType === 'franchising';

  const id = isAgent ? data.agency.id : data.id;
  const url = isAgent ? data.agency.url : data.url;
  const image = isAgent ? data.agency.image : data.image;
  const location = isAgent ? data.agency.location : data.location;

  useEffect(() => {
    setAgencyPageData(data);
    setSegmentAgencyTrackingData(baseAgencyInfo(data));
    updateGA4AgencyTrackingData({
      id: parseInt(data.id.toString(), 10),
      realEstateAds: data.realEstateAds,
      dataType: data.dataType,
      name: data.name,
      location,
      agency: isAgent ? data.agency : undefined,
      isPaid: isAgency ? data.isPaid : false,
      groupId: dataType === 'franchising' ? data.groupId : undefined,
      ...(isAgency && !data.isPaid && { realEstateIdList: [] }),
    });
  }, [data, dataType, isAgency, isAgent, location, setAgencyPageData]);

  useAgencySeoData();

  const setContactSourcePage = useSetAtom(contactSourcePageAtom);

  useEffect(() => {
    setEntryPoint(getEntryPoint(dataType));
  }, [dataType]);

  useEffect(() => {
    setContactSourcePage(ContactSourcePage.AGENCY_DETAIL);
  }, [setContactSourcePage]);

  useEffect(() => {
    removePageFragmentWithPrefix(PageFragmentPrefix.ACTIVE_IMAGE);
    removePageFragmentWithPrefix(PageFragmentPrefix.GEO_HASH_PARAM);
  }, []);

  useUpdateQueryParams();

  return (
    <>
      {isAgency && (
        <UnicreditUTag
          shouldLoad={data.customAgencyType === 'unicredit'}
          initConfigTrack={{
            section: 'agency_detail',
          }}
        />
      )}

      <SearchLayout>
        <SearchLayout.Header>
          <Header
            showBrand
            showUser
            showMenu
            showUserOnMobile
            backNavOnClick={() => history.back()}
          />
          <FormBar
            customClass={clsx(
              css['ag-agency__formBar'],
              /* Makes the actions invisible on mobile during load to avoid CLS */
              !isOnClient && 'is-mobileHydrating'
            )}
          >
            <AgencyFormBar />
          </FormBar>
        </SearchLayout.Header>
        <SearchLayout.Main>
          <SearchLayout.ResultsWrapper>
            <SearchLayout.Results>
              {isAgency && (
                <>
                  {isFeatureEnabled('AGENCY_BREADCRUMBS') && (
                    <div className={css[Styles.breadcrumbs]}>
                      <BreadcrumbsLite breadcrumbs={data.breadcrumbs} />
                    </div>
                  )}
                  <AgencyCover data={data} />
                  {!maxScreenWidthSm && <AgencyStickyHeader data={data} />}
                  <div className={css['ag-agency__mainList']}>
                    <AgencyRealEstateList referrer={referrer} data={data} />
                  </div>
                </>
              )}

              {isFranchising && (
                <>
                  <Breadcrumb
                    config={data.breadcrumbs}
                    customClass={css[Styles.breadcrumbs]}
                  />
                  <FranchisingCover data={data} />
                  {!maxScreenWidthSm && (
                    <FranchisingStickyHeader
                      phones={data.phones}
                      image={data.image}
                    />
                  )}
                  <div className={css['ag-agency__mainList']}>
                    <RealEstateResultsList
                      agencyType={dataType}
                      agencyName={name}
                      totalAgencies={data.totalAgencies}
                      realEstateAds={data.realEstateAds}
                      referrer={referrer}
                    />
                  </div>
                </>
              )}

              {isAgent && (
                <>
                  <AgentCover data={data} />
                  {!maxScreenWidthSm && (
                    <AgentStickyHeader
                      gender={data.gender}
                      name={data.name}
                      phones={data.phones}
                      url={data.agency.url}
                    />
                  )}
                  <div className={css['ag-agency__mainList']}>
                    <RealEstateResultsList
                      agencyType={dataType}
                      agencyName={name}
                      realEstateAds={data.realEstateAds}
                      referrer={referrer}
                    />
                  </div>
                </>
              )}
              <Banner
                id={
                  maxScreenWidthSm
                    ? 'div-gpt-ad-1699287136550-0'
                    : 'div-gpt-ad-1699286985714-0'
                }
              />
            </SearchLayout.Results>
            <FooterColumnCompact customClass={css[Styles.footer]} />
          </SearchLayout.ResultsWrapper>
          <SearchLayout.Map>
            <AgencyRealEstateSearchMap
              agency={{
                url,
                id,
                logoUrl: image || '',
                lat: location.latitude,
                lng: location.longitude,
              }}
              enableMakers={showMap || layoutMode === 'map-besides-the-list'}
            />
          </SearchLayout.Map>
        </SearchLayout.Main>
      </SearchLayout>
    </>
  );
}
