import type { PropsWithChildren } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import {
  PepitaMediaObject,
  PepitaMediaObjectBottom,
  PepitaMediaObjectContent,
  PepitaMediaObjectTop,
} from 'src/libs/ui/pepita-media-object';
import { PepitaSkeleton } from 'src/libs/ui/pepita-skeleton';

import css from './styles.module.scss';

interface ListingCardSkeletonProps extends PropsWithChildren {
  isCompact?: boolean;
}

export function ListingCardSkeleton({ isCompact }: ListingCardSkeletonProps) {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  return (
    <PepitaMediaObject
      direction="colToRow"
      customClass={css['in-listingCardSkeleton']}
    >
      <PepitaSkeleton
        type="image"
        customClass={clsx(
          css['in-listingCardSkeleton__image'],
          isCompact && css['is-compact']
        )}
      />
      <PepitaMediaObjectContent
        customClass={clsx(
          css['in-listingCardSkeleton__content'],
          isCompact && css['is-compact']
        )}
      >
        <PepitaMediaObjectTop>
          <PepitaSkeleton
            type="text"
            customClass={css['in-listingCardSkeleton__price']}
          />
          <PepitaSkeleton
            type="text"
            customClass={css['in-listingCardSkeleton__title']}
          />
          <PepitaSkeleton
            type="row"
            customClass={clsx(css['in-listingCardSkeleton__features'])}
            items={3}
          />
        </PepitaMediaObjectTop>
        <PepitaMediaObjectBottom>
          <PepitaSkeleton
            type="row"
            items={!isCompact && !maxScreenWidthSm ? 4 : 3}
            customClass={clsx(
              css['in-listingCardSkeleton__actions'],
              !isCompact && css['in-listingCardSkeleton__actions--full']
            )}
          />
        </PepitaMediaObjectBottom>
      </PepitaMediaObjectContent>
    </PepitaMediaObject>
  );
}
