import { useMemo } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import type { ContactedListingsRequestParams } from 'src/pages/api/user/contacts/contacted-listings';
import type { ContactedListingsResponse } from 'src/repository/contacted-listings/types';

import { isFeatureEnabled } from 'src/config/features-toggle';

import type { Language } from 'src/types/translations';

import { http } from 'src/utils/client/http';
import { getContactedMessage } from '../utils/getContactedMessage';

export const useContactedListings = (listingUuids: string[] | undefined) => {
  const { trans } = useTranslations();
  const { locale } = useRouter() as { locale: Language };
  const { user } = useAuthContext();
  const listingUuidsToString = listingUuids?.join();

  const searchParams =
    user && listingUuidsToString
      ? ({
          listingUuids: listingUuidsToString,
          userUuid: user.uuid,
        } satisfies ContactedListingsRequestParams)
      : undefined;

  const { data } = useQuery({
    queryKey: ['contacted-listings', searchParams],
    queryFn: () =>
      http
        .get('/api-next/user/contacts/contacted-listings/', {
          searchParams,
        })
        .json<ContactedListingsResponse>(),
    enabled:
      Boolean(searchParams) && isFeatureEnabled('CONTACTED_LISTING_INFO'),
  });

  return useMemo(() => {
    if (!user || !isFeatureEnabled('CONTACTED_LISTING_INFO')) return [];

    if (!data) return undefined;

    return data.map((d) => ({
      uuid: d.uuid,
      message: getContactedMessage(d.contactedAt, locale, trans),
    }));
  }, [user, data, locale, trans]);
};
