import { ListingCardSkeleton } from 'src/components/ListingCardSkeleton';

import { SearchLayout } from 'src/entities/search/components/SearchLayout';

interface ListingCardListSkeletonProps {
  isCompact?: boolean;
}

const MAX_SKELETON_ITEMS = 4;

export function ListingCardListSkeleton({
  isCompact,
}: ListingCardListSkeletonProps) {
  return (
    <SearchLayout.List>
      {Array.from(Array(MAX_SKELETON_ITEMS), (_, i) => (
        <SearchLayout.ListItem key={i}>
          <ListingCardSkeleton isCompact={isCompact} />
        </SearchLayout.ListItem>
      ))}
    </SearchLayout.List>
  );
}
