import type { Agent } from 'src/repository/agency/types';

import { CoverTitle } from 'src/components/CoverPage/CoverTitle';

import { PepitaStripe } from 'src/libs/ui/pepita-stripe';

import { TeamItem } from '../TeamItem';

import css from './styles.module.scss';

enum Styles {
  el = 'ag-team',
  carouselSlide = 'ag-team__carouselSlide',
}

interface TeamProps {
  agencyName: string;
  agents: Agent[];
}

export function Team({ agencyName, agents }: TeamProps) {
  return (
    <div className={css[Styles.el]}>
      <CoverTitle title={agencyName} tag="p" />
      <PepitaStripe spaced>
        {agents.map((agent) => {
          const { gender, name, surname, thumbnail, id, url } = agent;

          return (
            <PepitaStripe.Slide
              customClass={css[Styles.carouselSlide]}
              key={id}
            >
              <TeamItem
                url={url}
                gender={gender}
                name={name}
                surname={surname}
                thumbnail={thumbnail}
              />
            </PepitaStripe.Slide>
          );
        })}
      </PepitaStripe>
    </div>
  );
}
