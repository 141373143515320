import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import type { CONTRACT } from 'src/constants/typologies';

import { useContractOptions } from './useContractOptions';

import {
  PepitaSelect,
  type PepitaSelectProps,
} from 'src/libs/ui/pepita-select';

import css from './styles.module.scss';

interface ContractSelectProps {
  contractId?: CONTRACT;
  onChange: (contractId: CONTRACT) => void;
  inModal?: boolean;
}

type Option = Exclude<PepitaSelectProps['value'], undefined>;

export function ContractSelect({
  contractId,
  onChange,
  inModal = false,
}: ContractSelectProps) {
  const { trans } = useTranslations();
  const options = useContractOptions();
  const [selection, setSelection] = useState<Option>(() => {
    const defaultIndex = options.findIndex((opt) => opt.value === contractId);

    if (defaultIndex === -1) {
      return options[options.length - 1];
    }

    return options[defaultIndex];
  });

  const handleSelectContractId = (option: Option) => {
    if (option.value !== selection.value) {
      onChange(option.value as CONTRACT);
    }

    setSelection(option);
  };

  const label = trans('lbl_category', { capitalize: true });

  return (
    <>
      {inModal && (
        <span className={css['ag-contractSelect__label']}>{label}</span>
      )}
      <PepitaSelect
        customClass={clsx(
          css['ag-contractSelect'],
          inModal && css['ag-contractSelect--inModal'],
          selection && 'is-selected'
        )}
        title={label}
        onChange={(option) => handleSelectContractId(option)}
        options={options}
        placeholder={trans('lbl_all_ads', { capitalize: true })}
        confirmLabel={trans('act_confirm')}
        value={selection}
      />
    </>
  );
}
