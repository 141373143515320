import { isFeatureEnabled } from 'src/config/features-toggle';

import type { SortingSelectConfigItem } from './types';
import { SORT_CRITERION, SORT_VALUES } from './types';

export type ExcludedOptions = (typeof config)[number]['value'];

const isListingSearchNewSortingEnabled = isFeatureEnabled(
  'LISTING_SEARCH_NEW_SORTING'
);

const config: SortingSelectConfigItem[] = [
  {
    label: 'lbl_automatic', // i18n-extract-keys ["lbl_automatic"],
    value: SORT_VALUES.AUTOMATIC,
    criterion: SORT_CRITERION.AUTOMATIC,
  },
  {
    label: 'piu_rilevanti', // i18n-extract-keys ["piu_rilevanti"],
    value: SORT_VALUES.MORE_RELEVANT,
    criterion: SORT_CRITERION.RELEVANCE,
  },
  {
    label: 'piu_costosi', // i18n-extract-keys ["piu_costosi"]
    value: SORT_VALUES.MORE_EXPENSIVE,
    criterion: SORT_CRITERION.PRICE,
    direction: 'desc',
  },
  {
    label: 'piu_grandi', // i18n-extract-keys ["piu_grandi"]
    value: SORT_VALUES.LARGER,
    criterion: SORT_CRITERION.SURFACE,
    direction: 'desc',
  },
  {
    label: 'piu_locali', // i18n-extract-keys ["piu_locali"]
    value: SORT_VALUES.MORE_LOCAL,
    criterion: SORT_CRITERION.ROOMS,
    direction: 'desc',
  },
  {
    label: 'piu_recenti', // i18n-extract-keys ["piu_recenti"]
    value: SORT_VALUES.LATEST,
    criterion: isListingSearchNewSortingEnabled
      ? SORT_CRITERION.CREATION_DATE
      : SORT_CRITERION.MODIFICATION_DATE,
    direction: 'desc',
  },
  {
    label: 'meno_costosi', // i18n-extract-keys ["meno_costosi"]
    value: SORT_VALUES.LESS_EXPENSIVE,
    criterion: SORT_CRITERION.PRICE,
    direction: 'asc',
  },
  {
    label: 'meno_grandi', // i18n-extract-keys ["meno_grandi"]
    value: SORT_VALUES.LESS_BIG,
    criterion: SORT_CRITERION.SURFACE,
    direction: 'asc',
  },
  {
    label: 'meno_locali', // i18n-extract-keys ["meno_locali"]
    value: SORT_VALUES.LESS_LOCAL,
    criterion: SORT_CRITERION.ROOMS,
    direction: 'asc',
  },
  {
    label: 'meno_recenti', // i18n-extract-keys ["meno_recenti"]
    value: SORT_VALUES.OLDER,
    criterion: isListingSearchNewSortingEnabled
      ? SORT_CRITERION.CREATION_DATE
      : SORT_CRITERION.MODIFICATION_DATE,
    direction: 'asc',
  },
];

export const getSortingConfig = (excludedOptions?: ExcludedOptions[]) => {
  return excludedOptions?.length
    ? config.filter((option) => {
        const optionValue = option.value;

        return !excludedOptions.includes(optionValue);
      })
    : config;
};
