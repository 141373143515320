import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import Link from 'next/link';
import type { AgentData } from 'src/repository/agency/types/formatted-data-types';

import { CoverPageMap } from 'src/components/CoverPage/CoverPageMap';
import { CoverTitle } from 'src/components/CoverPage/CoverTitle';
import { LocationLink } from 'src/components/CoverPage/LocationLink';

import { AgentContacts } from '../AgentContacts';
import { TagList } from '../TagList';

import css from './styles.module.scss';

interface AgentOverviewProps {
  data: AgentData;
  agencyName: string;
  agencyUrl: string;
}

export function AgentOverview({
  data,
  agencyName,
  agencyUrl,
}: AgentOverviewProps) {
  const { name, agency, realEstateAds, realEstateSales, phones } = data;
  const {
    address,
    location: { latitude, longitude },
    image,
    url,
  } = agency;
  const { trans } = useTranslations();
  const [openMapDialog, setOpenMapDialog] = useState(false);

  return (
    <div className={css['ag-agentOverview']}>
      <div>
        <CoverTitle title={name} />
        <p className={css['ag-agentOverview__agencyLink']}>
          <Link href={agencyUrl}>{agencyName}</Link>
        </p>

        {openMapDialog &&
          address &&
          Boolean(latitude) &&
          Boolean(longitude) && (
            <CoverPageMap
              latitude={latitude}
              longitude={longitude}
              agencyLogo={image}
              address={address}
              title={trans('position_agency', { params: [agencyName] })}
              onClose={() => setOpenMapDialog(false)}
            />
          )}
        <LocationLink
          address={address}
          latitude={latitude}
          longitude={longitude}
          onLocationClick={() => setOpenMapDialog(true)}
        />

        <TagList
          realEstateAds={realEstateAds}
          realEstateSales={realEstateSales}
          customClass={css['ag-agentOverview__tagList']}
        />
      </div>
      <AgentContacts viewInModal phones={phones} url={url} />
    </div>
  );
}
