import { useEffect, useRef, useSyncExternalStore } from 'react';
import { useRouter } from 'next/router';

import { useAgencyTracking } from './useAgencyTracking';

import { useSendGA4Event } from 'src/tracking/ga/hooks/useSendGAEvent';
import {
  getGA4AgencyTrackingData,
  subscribeGA4AgencyTrack,
} from 'src/tracking/ga/utils/store';
import { getContractType, getTypologiesByFilter } from 'src/tracking/utils';
import { GATrackingEvent } from 'src/tracking/utils/enums';
import { getAgencyPageSectionName, getGA4AgencyInfo } from '../tracking/utils';

export const useAgencyPageViewTrack = (referrer: string) => {
  const { locale } = useRouter();
  const { trackPageViewed } = useAgencyTracking();
  const trackSent = useRef(false);
  const sendGA4Event = useSendGA4Event();
  const agencyTrackingData = useSyncExternalStore(
    subscribeGA4AgencyTrack,
    getGA4AgencyTrackingData,
    () => undefined
  );

  useEffect(() => {
    if (
      !agencyTrackingData ||
      agencyTrackingData.realEstateIdList === undefined
    ) {
      return;
    }

    const { realEstateIdList, query, dataType } = agencyTrackingData;
    const location =
      dataType !== 'agent'
        ? agencyTrackingData.location
        : agencyTrackingData.agency?.location;

    sendGA4Event(
      dataType === 'agent'
        ? GATrackingEvent.AGENT_CARD_LOAD
        : GATrackingEvent.AGENCY_CARD_LOAD,
      {
        ...getGA4AgencyInfo(),
        agency_province:
          dataType !== 'franchising' && location
            ? location.province?.name
            : undefined,
        agency_city:
          dataType !== 'franchising' && location
            ? location.city?.name
            : undefined,
        section_name: getAgencyPageSectionName(dataType),
        language: locale,
        properties_contract: getContractType(query?.contractId),
        properties_type: getTypologiesByFilter(
          query?.categoryId,
          query?.typeId
        ),
        properties_ids: realEstateIdList.join('|'),
      }
    );

    sendGA4Event(GATrackingEvent.VIEW_ITEM_LIST, {
      ecommerce: {
        items: agencyTrackingData.realEstateCommercialItemValue,
      },
    });
  }, [agencyTrackingData, locale, sendGA4Event]);

  useEffect(() => {
    if (trackSent.current) {
      return;
    }

    if (agencyTrackingData) {
      const { dataType, realEstateIdList, isPaid } = agencyTrackingData;
      const isAgency = dataType === 'agency' || dataType === 'court';

      if ((isAgency && isPaid) || realEstateIdList?.length) {
        trackPageViewed(referrer);
        trackSent.current = true;
      }
    }
  }, [agencyTrackingData, referrer, trackPageViewed]);
};
