import { useEffect, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import { bannersDataAtom } from 'src/atoms/bannersDataAtom';
import { bannersSlotsAtom } from 'src/atoms/bannersSlotsAtom';

import { BannerSlot } from 'src/components/BannerSlot';
import { loadSlots, unloadBanner } from 'src/components/BannerSlot/banners';

interface BannerProps {
  id: string;
  customClass?: string;
}

export function Banner({ id, customClass }: BannerProps) {
  const [bannerReady, setBannerReady] = useState(false);
  const banners = useAtomValue(bannersDataAtom);
  const setBannersSlot = useSetAtom(bannersSlotsAtom);

  useEffect(() => {
    if (!banners) return;

    loadSlots(banners.banners, banners.settings).then((slots) => {
      setBannersSlot(slots);
      setBannerReady(true);
    });

    return () => {
      unloadBanner();
    };
  }, []);

  return bannerReady && banners?.banners.length ? (
    <BannerSlot id={id} customClass={customClass} />
  ) : null;
}
