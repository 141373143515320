import type { PropsWithChildren } from 'react';
import { useState } from 'react';
import { useIsomorphicLayoutEffect } from '@pepita-react/react-utils';

import css from './styles.module.scss';

interface TabPanelProps {
  index?: number;
  selected?: boolean;
  forceUpdate?: boolean;
}

export function TabPanel({
  index,
  selected,
  forceUpdate = false,
  children,
}: PropsWithChildren<TabPanelProps>) {
  const [forcedRender, setForcedRender] = useState<number>();

  useIsomorphicLayoutEffect(() => {
    if (!forcedRender && forceUpdate && selected) {
      setForcedRender(Math.random());
    }
  }, [forcedRender, forceUpdate, selected]);

  const tabKey = `tabpanel-${forcedRender ?? index}`;

  return (
    <div
      key={tabKey}
      role="tabpanel"
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      aria-hidden={!selected ? 'true' : 'false'}
      hidden={!selected}
      className={css['in-tabPanel']}
    >
      {children}
    </div>
  );
}
