import placeholder from '@pepita-fe/sprite-placeholder-manager/sprite.svg';
import type { AgentGender } from 'src/repository/agency/types';

import { LinkOrHtmlTag } from 'src/components/LinkOrHtmlTag';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import css from './styles.module.scss';

enum Styles {
  el = 'ag-teamItem',
  figure = 'ag-teamItem__figure',
  placeholder = 'ag-teamItem__placeholder',
  name = 'ag-teamItem__name',
}
interface TeamItemProps {
  gender: AgentGender;
  name: string;
  surname: string;
  thumbnail?: string;
  url?: string;
}

export function TeamItem({
  gender,
  name,
  surname,
  thumbnail,
  url,
}: TeamItemProps) {
  return (
    <LinkOrHtmlTag
      tag="div"
      url={url}
      target="_blank"
      className={css[Styles.el]}
    >
      <PepitaFigure
        customClass={css[Styles.figure]}
        as={'figure'}
        ratio="square"
        bordered={true}
        figureContent={
          thumbnail || (
            <PepitaIcon
              name={`agent_${gender}`}
              spriteUrl={placeholder}
              customClass={css[Styles.placeholder]}
            />
          )
        }
      />

      <p className={css[Styles.name]}>{`${name} ${surname}`}</p>
    </LinkOrHtmlTag>
  );
}
