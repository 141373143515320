import type { PropsWithChildren, ReactNode } from 'react';
import { useIsOnClient } from '@indomita-react/custom-hooks';
import clsx from 'clsx';

import css from './styles.module.scss';

interface RealEstateListHeaderProps extends PropsWithChildren {
  title: ReactNode;
  dataCy?: string;
}

export function RealEstateListHeader({
  title,
  dataCy,
  children,
}: RealEstateListHeaderProps) {
  const isOnClient = useIsOnClient();

  return (
    <div
      className={clsx(
        css['in-realEstateListHeader'],
        /* Makes the actions invisible on mobile during load to avoid CLS */
        !isOnClient && 'is-mobileHydrating'
      )}
      data-cy={dataCy}
    >
      <div className={css['in-realEstateListHeader__title']}>{title}</div>
      {children}
    </div>
  );
}
