import { useTranslations } from '@pepita-react/i18n';
import type { AgencyData } from 'src/repository/agency/types';

import { AgencyList } from 'src/components/AgencyList';

import { RealEstateResultsList } from '../RealEstateResultsList';

interface AgencyRealEstateListProps {
  data: AgencyData;
  referrer: string;
}

export function AgencyRealEstateList({
  data,
  referrer,
}: AgencyRealEstateListProps) {
  const { dataType, name, isPaid, nearAgencies, realEstateAds } = data;
  const { trans } = useTranslations();

  if (!isPaid && nearAgencies) {
    return (
      <AgencyList title={trans('agency_row_title')} agencies={nearAgencies} />
    );
  }

  return (
    <RealEstateResultsList
      agencyType={dataType}
      agencyName={name}
      realEstateAds={realEstateAds}
      referrer={referrer}
    />
  );
}
