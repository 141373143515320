import Link from 'next/link';

interface BreadcrumbLinkProps {
  link: string;
  label: string;
  serverSideNavigation?: boolean;
}

export function BreadcrumbLink({
  link,
  label,
  serverSideNavigation = false,
}: BreadcrumbLinkProps) {
  return serverSideNavigation ? (
    <a className="nd-list__link" href={link}>
      {label}
    </a>
  ) : (
    <Link href={link} passHref className="nd-list__link">
      {label}
    </Link>
  );
}
