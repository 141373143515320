import { useBreakpoint } from '@pepita-react/use-breakpoint';
import type { AgencyData } from 'src/repository/agency/types';

import { AgencyPlaceholder } from 'src/components/AgencyPlaceholder';
import { CoverPageHeader } from 'src/components/CoverPage/CoverPageHeader';
import { CoverPageWrapper } from 'src/components/CoverPage/CoverPageWrapper';

import { AgencyTabs } from '../AgencyTabs';
import { TagList } from '../TagList';

interface AgencyCoverProps {
  data: AgencyData;
}

export function AgencyCover({ data }: AgencyCoverProps) {
  const [minScreenWidthSm] = useBreakpoint({ from: 'sm' });
  const { headerBackgroundColor, image, realEstateAds, realEstateSales, type } =
    data;

  return (
    <CoverPageWrapper>
      <CoverPageHeader
        image={image}
        placeholderImage={<AgencyPlaceholder agencyType={type} />}
        headerBackgroundColor={headerBackgroundColor}
      >
        {minScreenWidthSm === true && (
          <TagList
            isReversed={!headerBackgroundColor}
            realEstateAds={realEstateAds}
            realEstateSales={realEstateSales}
          />
        )}
      </CoverPageHeader>
      <AgencyTabs data={data} />
    </CoverPageWrapper>
  );
}
