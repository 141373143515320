import { useMemo } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import { CONTRACT } from 'src/constants/typologies';

import { getCategoriesConfig } from 'src/utils/categoriesConfig';
import { getListingTypologies } from 'src/utils/getTypologies';
import { getOptions } from './utils';

export const useTypologyOptions = (contractId = CONTRACT.ALL) => {
  const { trans } = useTranslations();

  const options = useMemo(() => {
    const contractIds =
      contractId === CONTRACT.VENDUTO_AFFITTATO || contractId === CONTRACT.ALL
        ? [CONTRACT.VENDITA, CONTRACT.AFFITTO]
        : [contractId];

    return getOptions(
      getCategoriesConfig(getListingTypologies()),
      contractIds
    ).map((option) => ({
      ...option,
      // i18n-extract-disable-next-line
      label: trans(option.label),
      children: option.children.map((child) => ({
        ...child,
        // i18n-extract-disable-next-line
        label: trans(child.label),
      })),
    }));
  }, [contractId]);

  return options;
};
