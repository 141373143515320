import type { ReactNode } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

import { TabPanel } from '../TabPanel';

export interface TabPanelsProps {
  children: ReactNode;
  selected?: number;
}

export function TabPanels({ children, selected }: TabPanelsProps) {
  return (
    <>
      {Children.map(
        Children.toArray(children).filter(Boolean),
        (child, index) => {
          if (isValidElement(child) && child.type === TabPanel) {
            return cloneElement(child, {
              ...child.props,
              index,
              selected: index === selected,
            });
          }

          // eslint-disable-next-line no-console
          console.warn('`TabPanels` children should be of type `TabPanel`');

          return null;
        }
      )}
    </>
  );
}
