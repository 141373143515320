import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import type { AgencySeoData } from 'src/repository/agency/types';
import { agencySeoQueryKey } from 'src/repository/agency/utils';

import { agencyPageDataAtom } from '../atoms';
import { realEstateListCountAtom } from '../atoms/realEstateListCountAtom';

import { seoDataAtom } from 'src/components/SEOTags/atoms/SeoDataAtom';

import { useAgencyQueryParams } from './useAgencyQueryParams';

import { http } from 'src/utils/client/http';
import { deepEqual } from 'src/utils/object';
import { getSeoDataAtomValues } from '../utils/seo';

export const useAgencySeoData = () => {
  const agencyPageData = useAtomValue(agencyPageDataAtom);
  const totalAds = useAtomValue(realEstateListCountAtom);
  const setSeoData = useSetAtom(seoDataAtom);

  const { id, pageType, lang, ...searchParams } = useAgencyQueryParams();

  const { data: seoData } = useQuery<AgencySeoData>({
    queryKey: agencySeoQueryKey(id, pageType, searchParams, lang),
    queryFn: () =>
      http
        .get(`/api-next/agency/${id}/seo/`, {
          searchParams: {
            ...searchParams,
            type: pageType,
            lang,
          },
        })
        .json(),
  });

  useEffect(() => {
    if (!seoData || !agencyPageData) {
      return;
    }

    const { breadcrumbsSchema, url } = agencyPageData;

    const seoDataValue = getSeoDataAtomValues(
      { breadcrumbsSchema, url },
      seoData,
      typeof searchParams.pag === 'number' ? searchParams.pag : 1,
      totalAds || 0,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      searchParams as any
    );

    if (deepEqual(seoDataValue, seoData)) {
      return;
    }

    setSeoData(
      getSeoDataAtomValues(
        { breadcrumbsSchema, url },
        seoData,
        typeof searchParams.pag === 'number' ? searchParams.pag : 1,
        totalAds || 0,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        searchParams as any
      )
    );
  }, [seoData, totalAds, searchParams, setSeoData, agencyPageData]);
};
