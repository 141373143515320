import { useTranslations } from '@pepita-react/i18n';

import { useNumberFormatterCallback } from 'src/hooks/useFormatters';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import { useSearchFormCount } from 'src/views/Agency/hooks/useSearchFormCount';

interface FooterButtonProps {
  onClick: () => void;
}

export function FooterButton({ onClick }: FooterButtonProps) {
  const { trans } = useTranslations();
  const formatNumber = useNumberFormatterCallback();
  const { count } = useSearchFormCount();

  return (
    <PepitaButton
      block
      variant="accent"
      loading={count || count === 0 ? false : true}
      onClick={onClick}
    >
      {count && count > 0
        ? trans('act_show_all_result', {
            count,
            params: [formatNumber(count || 0, 0)],
          })
        : trans('act_no_result')}
    </PepitaButton>
  );
}
