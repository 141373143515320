import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import { useAtomicStateAtomSetState } from 'src/atoms/atomic-state';

import { getSortingConfig } from 'src/components/SortingSelect/config';
import type {
  SORT_CRITERION,
  SortingSelectConfigItem,
} from 'src/components/SortingSelect/types';
import {
  type SORT_DIRECTION,
  SORT_VALUES,
} from 'src/components/SortingSelect/types';

import { queryParamsStateAtom } from 'src/views/Agency/atoms/queryParamsStateAtom';
import { useUpdateQueryParams } from 'src/views/Agency/hooks/useUpdateQueryParams';

export const useAgencyRealEstateSortingSelect = (
  sortBy: SORT_CRITERION,
  sortDir: SORT_DIRECTION
) => {
  const { trans } = useTranslations();

  const sortingOptions = getSortingConfig([SORT_VALUES.AUTOMATIC]);
  const [selected, setSelected] = useState<SortingSelectConfigItem>(() => {
    const index = sortingOptions.findIndex(
      (opt) => opt.criterion === sortBy && opt.direction === sortDir
    );

    if (index === -1) {
      return {
        ...sortingOptions[0],
        label: trans(sortingOptions[0].label), // i18n-extract-disable-line
      };
    }

    return {
      ...sortingOptions[index],
      label: trans(sortingOptions[index].label), // i18n-extract-disable-line
    };
  });

  const setQueryParam = useAtomicStateAtomSetState(queryParamsStateAtom);

  useUpdateQueryParams();

  const options = sortingOptions.map((item) => ({
    ...item,
    label: trans(item.label), // i18n-extract-disable-line
  }));

  const handleChange = (option) => {
    setQueryParam((prevState) => ({
      ...prevState,
      sortBy: option.criterion || undefined,
      sortDir: option.direction,
    }));
    setSelected(option);
  };

  return {
    options,
    value: selected,
    handleChange,
  };
};
