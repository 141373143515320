import { useEffect } from 'react';
import Script from 'next/script';

interface TrackUnicreditVisitArgs {
  section: 'realEstate_detail' | 'agency_detail';
  realEstateId?: string | number;
  isProject?: boolean;
}

function trackUnicreditVisit(config: TrackUnicreditVisitArgs) {
  const { section, realEstateId, isProject } = config;
  const utag_data = (window.utag_data = {});

  if (section === 'realEstate_detail') {
    utag_data['scheda_immobile'] = realEstateId;
    utag_data['tipo_annuncio'] = isProject ? 'progetto' : 'annuncio';
  }
}

/**
 *Displays a script tag loading the Unicredit analytics utag.js file
 *
 * @remarks
 * //to check on sending tracking follow tellium doc https://docs.tealium.com/platforms/javascript/debugging/
 * @component
 *
 * @param {Boolean} shouldLoad - Indicates if the script should be loaded or not
 * @param {Object} TrackUnicreditVisitArgs - Used to initialize the values used in the {trackUnicreditVisit } tracking function before the library is loaded
 * @return {null|JSX.Element} - The  Script element if load is true, null otherwise
 */

export function UnicreditUTag({
  shouldLoad,
  initConfigTrack,
}: {
  shouldLoad: boolean;
  initConfigTrack: TrackUnicreditVisitArgs;
}): null | JSX.Element {
  useEffect(() => {
    if (shouldLoad) {
      trackUnicreditVisit(initConfigTrack);
    }
  }, [shouldLoad]);

  if (!shouldLoad) {
    return null;
  }

  return (
    <Script
      src="https://tags.tiqcdn.com/utag/unicreditsubitocasa/network/prod/utag.js"
      async
      defer
      strategy="afterInteractive"
    />
  );
}
