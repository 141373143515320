import type { FranchisingData } from 'src/repository/agency/types/formatted-data-types';

import { AgencyPlaceholder } from 'src/components/AgencyPlaceholder';
import { CoverPageHeader } from 'src/components/CoverPage/CoverPageHeader';
import { CoverPageWrapper } from 'src/components/CoverPage/CoverPageWrapper';

import { AGENCY_TYPE } from 'src/constants/real-estate';

import { FranchisingOverview } from '../FranchisingOverview';

interface FranchisingCoverProps {
  data: FranchisingData;
}

export function FranchisingCover({ data }: FranchisingCoverProps) {
  const { image } = data;

  return (
    <CoverPageWrapper>
      <CoverPageHeader
        image={image}
        placeholderImage={<AgencyPlaceholder agencyType={AGENCY_TYPE.AGENCY} />}
      />
      <FranchisingOverview data={data} />
    </CoverPageWrapper>
  );
}
