import { useEffect, useRef, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import type { AgencyPageType } from 'src/repository/agency/types';

import { ContactStripe } from 'src/components/ContactStripe';
import { EmptyState } from 'src/components/EmptyState';
import { ListingCardListSkeleton } from 'src/components/ListingCardListSkeleton';
import { Pagination, usePaginationItems } from 'src/components/Pagination';
import { RealEstateListHeaderSkeleton } from 'src/components/RealEstateListHeader/RealEstateListHeaderSkeleton';
import { useContactedListings } from 'src/components/SearchList/hooks/useContactedListings';

import { ListingCard } from 'src/entities/listing/components';
import { SearchLayout } from 'src/entities/search/components/SearchLayout';

import { formatGA4RealEstateTrackingData } from 'src/tracking/ga/utils/formatRealEstateTrackingData';
import { getPropertiesCommercialItemsValue } from 'src/tracking/ga/utils/getPropertiesCommercialItemsValue';
import { getRealEstateCommercialItemsValue } from 'src/tracking/ga/utils/getRealEstateCommercialItemsValue';
import { setGA4trackingData } from 'src/tracking/ga/utils/store';
import { setSegmentTrackingData } from 'src/tracking/segment/utils';

import { getListMaxPages } from 'src/utils/real-estate';

import { useAgencyTrackListingShown } from '../../hooks/useAgencyTrackingList';
import { useRealEstateList } from '../../hooks/useRealEstateList';
import { getAgencyPageSectionName } from '../../tracking/utils';
import { RealEstateResultsHeader } from '../RealEstateResultsHeader';

import css from './styles.module.scss';

interface RealEstateListProps {
  agencyName: string;
  agencyType: AgencyPageType;
  totalAgencies?: number;
  realEstateAds?: number;
  referrer: string;
}

enum Styles {
  pagination = 'ag-realEstateResultsList__pagination',
  skeletonContainer = 'ag-realEstateResultsList__skeletonContainer',
}

export function RealEstateResultsList({
  agencyName,
  agencyType,
  totalAgencies,
  realEstateAds,
  referrer,
}: RealEstateListProps) {
  const { data, currentPage } = useRealEstateList();
  const maxPages = data ? getListMaxPages(data.count) : 0;
  const realEstateListRef = useRef<HTMLDivElement>(null);
  const [listOffsetTop, setListOffsetTop] = useState(0);
  const pages = usePaginationItems(currentPage, maxPages, 11, true);
  const { trans } = useTranslations();

  const getEmptyText = () => {
    if (agencyType === 'agent' && !realEstateAds) {
      return trans('agent_view_no_ads');
    }

    if (!realEstateAds) {
      return trans('agency_view_no_ads');
    }

    return undefined;
  };

  useAgencyTrackListingShown({
    realEstateList: data,
    currentPage,
    maxPages,
    referrer,
  });

  useEffect(() => {
    if (!realEstateListRef.current) return;

    setListOffsetTop(realEstateListRef.current.offsetTop);
  }, []);

  const contactedListings = useContactedListings(
    data?.listing && data.listing.map((l) => l.realEstate.uuid)
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    setGA4trackingData({
      realEstateList: data.listing.map((listing, index) => ({
        propertiesCommercialItemValue: getRealEstateCommercialItemsValue([
          listing.realEstate,
        ]),
        realEstateCommercialItemValue: getPropertiesCommercialItemsValue(
          listing.realEstate
        ),
        realEstateTrackingData: formatGA4RealEstateTrackingData({
          realEstate: {
            ...listing.realEstate,
            currentPage,
            positionList: index,
            origin: 'List',
          },
          trans,
        }),
      })),
      section_name: getAgencyPageSectionName(agencyType),
    });

    setSegmentTrackingData({
      realEstateList: data.listing.map((listing, index) => ({
        'ID Listing': listing.realEstate.id,
        'Index Number': index,
        'Map or List Visualization': 'List',
        'Page Number': currentPage,
      })),
    });
  }, [data, currentPage, trans, agencyType]);

  if (!data) {
    return (
      <div className={css[Styles.skeletonContainer]}>
        <RealEstateListHeaderSkeleton />
        <ListingCardListSkeleton />
      </div>
    );
  }

  if (!data.listing.length) {
    return <EmptyState type="agency" text={getEmptyText()} />;
  }

  return (
    <div ref={realEstateListRef}>
      <RealEstateResultsHeader
        count={data.count}
        name={agencyName}
        totalAgencies={totalAgencies}
      />
      <SearchLayout.List>
        {data.listing.map((realEstateItem, i) => {
          const { realEstate, idGeoHash } = realEstateItem;
          const contactedMessage = contactedListings?.find(
            (cl) => cl.uuid === realEstate.uuid
          )?.message;

          return (
            <SearchLayout.ListItem key={realEstate.id}>
              <ListingCard
                idGeoHash={idGeoHash}
                realEstate={realEstate}
                hasContactedMessage={Boolean(contactedMessage)}
              >
                {contactedMessage && (
                  <ContactStripe message={contactedMessage} />
                )}
                <ListingCard.Property enableMouseHoverEvents>
                  <ListingCard.PropertyMedia
                    lazyLoad={i > 0}
                    preload={i === 0}
                  />
                  <ListingCard.PropertyContent>
                    <ListingCard.Price />
                    <ListingCard.Title />
                    <ListingCard.FeatureList />
                    <ListingCard.Description />
                    <ListingCard.Actions>
                      <ListingCard.SaveButton />
                    </ListingCard.Actions>
                  </ListingCard.PropertyContent>
                </ListingCard.Property>
                <ListingCard.Units />
              </ListingCard>
            </SearchLayout.ListItem>
          );
        })}
      </SearchLayout.List>
      <Pagination
        customClass={css[Styles.pagination]}
        controls={{
          prevPage: pages.prev,
          nextPage: pages.next,
          firstPage: pages.first,
          lastPage: pages.last,
        }}
        pages={pages.range}
        onPageClick={() => {
          document.documentElement.scrollTop = listOffsetTop;
        }}
      />
    </div>
  );
}
