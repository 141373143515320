import type { AgencySeoData } from 'src/repository/agency/types';

import type { SEOTagsType } from 'src/types/seo';

import { getSeoNextPage } from 'src/utils/getSeoData';

export const getSeoDataAtomValues = (
  { breadcrumbsSchema, url }: { breadcrumbsSchema: string; url: string },
  agencySeoData: AgencySeoData,
  currentPage: number,
  totalAds: number,
  queryParams: Record<string, string | string[] | undefined>
) => {
  const { title, description, canonical, alternate } = agencySeoData;
  const queryParamsKeysExceptPag = Object.keys(queryParams).filter(
    (key) => key !== 'pag'
  );
  const seoAtom: SEOTagsType = {
    title,
    description,
    canonical,
    alternate,
    breadCrumbsSchema: breadcrumbsSchema,
    prevPage: null,
    nextPage: null,
  };

  // robots
  if (currentPage === 1 && !queryParamsKeysExceptPag.length) {
    seoAtom.robots = 'index, follow';
  }

  if (currentPage > 1 && !queryParamsKeysExceptPag.length) {
    seoAtom.robots = 'noindex, follow';
  }

  const nextPage = getSeoNextPage(url, currentPage, totalAds);

  // prevPage and nextPage
  if (currentPage > 2) {
    seoAtom.prevPage = `${url}?pag=${currentPage - 1}`;
  }

  if (currentPage === 2) {
    seoAtom.prevPage = url;
  }

  if (nextPage) {
    seoAtom.nextPage = nextPage;
  }

  return seoAtom;
};
