import { PepitaSkeleton } from 'src/libs/ui/pepita-skeleton';

import css from './styles.module.scss';

export function RealEstateListHeaderSkeleton() {
  return (
    <div className={css['in-realEstateListHeaderSkeleton']}>
      <PepitaSkeleton
        type="text"
        theme="light"
        customClass={css['in-realEstateListHeaderSkeleton__title']}
      />
      <PepitaSkeleton
        theme="light"
        type="row"
        items={2}
        customClass={css['in-realEstateListHeaderSkeleton__select']}
      />
    </div>
  );
}
