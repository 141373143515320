import type { AgencyPhone } from 'src/repository/agency/types/formatted-data-types';

import { AgencyPlaceholder } from 'src/components/AgencyPlaceholder';
import { CoverPageStickyHeaderWrapper } from 'src/components/CoverPage/CoverPageStickyHeaderWrapper';

import { AGENCY_TYPE } from 'src/constants/real-estate';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import { FranchisingContact } from '../FranchisingContact';

import css from './style.module.scss';

interface FranchisingStickyHeaderProps {
  image?: string;
  phones: AgencyPhone[];
}

export function FranchisingStickyHeader({
  image,
  phones,
}: FranchisingStickyHeaderProps) {
  return (
    <CoverPageStickyHeaderWrapper>
      <PepitaFigure
        figureContent={
          image || <AgencyPlaceholder agencyType={AGENCY_TYPE.AGENCY} />
        }
        customClass={css['ag-franchisingSticky__figure']}
        imageCustomClass={css['ag-franchisingSticky__image']}
      />
      <FranchisingContact phones={phones} />
    </CoverPageStickyHeaderWrapper>
  );
}
