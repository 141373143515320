import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { useNumberFormatterCallback } from 'src/hooks/useFormatters';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';

import css from './styles.module.scss';

interface TagListProps {
  realEstateAds?: number;
  realEstateSales?: number;
  isReversed?: boolean;
  customClass?: string;
}

export function TagList({
  realEstateAds,
  realEstateSales,
  isReversed = false,
  customClass,
}: TagListProps) {
  const { trans } = useTranslations();
  const formatNumber = useNumberFormatterCallback();

  if (!realEstateAds && !realEstateSales) {
    return null;
  }

  return (
    <div className={clsx(css['ag-tagList'], customClass)}>
      {realEstateAds ? (
        <PepitaBadge
          variant={isReversed ? 'reversed' : undefined}
          customClass={css['ag-tagList__badge']}
        >
          <span>
            <strong>{`${formatNumber(realEstateAds)} `}</strong>
            {trans('lbl_ad', {
              count: realEstateAds,
            })}
          </span>
        </PepitaBadge>
      ) : null}

      {realEstateSales ? (
        <PepitaBadge
          variant={isReversed ? 'reversed' : undefined}
          customClass={css['ag-tagList__badge']}
        >
          <span>
            <strong>{`${formatNumber(realEstateSales)} `}</strong>
            {trans('lbl_single_sold_or_rented', {
              count: realEstateSales,
            })}
          </span>
        </PepitaBadge>
      ) : null}
    </div>
  );
}
