import type { AgencyData } from 'src/repository/agency/types';

import { AgencyPlaceholder } from 'src/components/AgencyPlaceholder';
import { CoverPageStickyHeaderWrapper } from 'src/components/CoverPage/CoverPageStickyHeaderWrapper';

import { AGENCY_TYPE } from 'src/constants/real-estate';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import { AgencyContacts } from '../AgencyContacts';

import css from './style.module.scss';

interface AgencyStickyHeaderProps {
  data: AgencyData;
}

export function AgencyStickyHeader({ data }: AgencyStickyHeaderProps) {
  const { image } = data;

  return (
    <CoverPageStickyHeaderWrapper>
      <PepitaFigure
        figureContent={
          image || <AgencyPlaceholder agencyType={AGENCY_TYPE.AGENCY} />
        }
        customClass={css['ag-agencySticky__figure']}
        imageCustomClass={css['ag-agencySticky__image']}
      />
      <AgencyContacts data={data} isHorizontal />
    </CoverPageStickyHeaderWrapper>
  );
}
