import placeholder from '@pepita-fe/sprite-placeholder-manager/sprite.svg';
import type { AgentData } from 'src/repository/agency/types/formatted-data-types';

import { AgencyPlaceholder } from 'src/components/AgencyPlaceholder';
import { CoverPageHeader } from 'src/components/CoverPage/CoverPageHeader';
import { CoverPageWrapper } from 'src/components/CoverPage/CoverPageWrapper';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { AgentOverview } from '../AgentOverview';

import css from './style.module.scss';

interface AgentCoverProps {
  data: AgentData;
}

export function AgentCover({ data }: AgentCoverProps) {
  const { headerBackgroundColor, image, agency, gender } = data;
  const { url, image: agencyImage, name: agencyName, type } = agency;

  return (
    <CoverPageWrapper>
      <CoverPageHeader
        headerBackgroundColor={headerBackgroundColor}
        placeholderImage={
          <PepitaIcon
            name={`agent_${gender}`}
            spriteUrl={placeholder}
            customClass={css['ag-agentCover__placeholder']}
          />
        }
        image={image}
        figureCustomClass={css['ag-agentCover__figureAgent']}
      >
        <PepitaFigure
          figureContent={agencyImage || <AgencyPlaceholder agencyType={type} />}
          customClass={css['ag-agentCover__figureAgency']}
          imageCustomClass={css['ag-agentCover__image']}
        />
      </CoverPageHeader>
      <AgentOverview data={data} agencyName={agencyName} agencyUrl={url} />
    </CoverPageWrapper>
  );
}
