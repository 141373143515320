import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import type { AgencyPhone } from 'src/repository/agency/types/formatted-data-types';

import { ContactsWrapper } from 'src/components/CoverPage/ContactsWrapper';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import {
  PurchaseAndContactEventValue,
  TrackHandleContactsClickType,
} from 'src/tracking/utils/enums';

import { useAgencyTracking } from 'src/views/Agency/hooks/useAgencyTracking';

import { Phone } from '../Phone';

interface AgentContactsProps {
  viewInModal?: boolean;
  isHorizontal?: boolean;
  phones: AgencyPhone[];
  url: string;
}

export function AgentContacts({
  viewInModal = false,
  isHorizontal,
  phones,
  url,
}: AgentContactsProps) {
  const { trans } = useTranslations();
  const { trackHandleContactsClick, trackAgencyContactSubmit } =
    useAgencyTracking();
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const phoneAgent = phones.length > 0 ? phones[0] : undefined;

  const handleAgencyClick = () => {
    location.href = url;
  };

  return (
    <ContactsWrapper isHorizontal={isHorizontal}>
      {!isHorizontal && (
        <PepitaButton
          variant="accent"
          onClick={handleAgencyClick}
          block={!maxScreenWidthSm}
        >
          {trans('see_agency')}
        </PepitaButton>
      )}
      {phoneAgent && (
        <Phone
          number={phoneAgent.value}
          type={phoneAgent.type}
          block={!maxScreenWidthSm && !isHorizontal}
          onShowPhoneClick={() => {
            trackAgencyContactSubmit({
              purchaseAndContactEventValue: PurchaseAndContactEventValue.PHONE,
              isVirtualPhone: phoneAgent.isVirtual,
            });
            trackHandleContactsClick(
              TrackHandleContactsClickType.TELEPHONE,
              phoneAgent.type === 'green-phone'
            );
          }}
          viewInModal={viewInModal && maxScreenWidthSm}
        />
      )}
    </ContactsWrapper>
  );
}
