import type { PropsWithChildren, ReactElement } from 'react';
import {
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useState,
} from 'react';

import type { TabListProps } from './TabList';
import { TabList } from './TabList';
import type { TabPanelsProps } from './TabPanels';
import { TabPanels } from './TabPanels';

type AllowedChild = TabListProps | TabPanelsProps;

interface TabsProps {
  onChange?: (key: number, name: string) => void;
  currentIndex?: number;
  children: ReactElement<AllowedChild> | ReactElement<AllowedChild>[];
}

export function Tabs({
  children,
  currentIndex = 0,
  onChange,
}: PropsWithChildren<TabsProps>) {
  const [selected, setSelected] = useState<number>(currentIndex);

  const handleSelectTab = useCallback(
    (index: number, name: string) => {
      if (typeof onChange === 'function') {
        onChange(index, name);
      }

      setSelected(index);
    },
    [onChange]
  );

  return (
    <>
      {Children.map(Children.toArray(children).filter(Boolean), (child) => {
        if (isValidElement(child)) {
          if (child.type === TabList) {
            return cloneElement(child, {
              ...child.props,
              onSelect: handleSelectTab,
              selected,
            });
          }

          if (child.type === TabPanels) {
            return cloneElement(child, {
              ...child.props,
              selected,
            });
          }
        }

        // eslint-disable-next-line no-console
        console.warn(
          '`Tabs` children should be of type `TabList` or `TabPanels`'
        );

        return null;
      })}
    </>
  );
}
