import dynamic from 'next/dynamic';

import type { AgencyRealEstateSearchMapProps } from './AgencyRealEstateSearchMap';

const DynamicAgencyRealEstateSearchMap = dynamic(
  () =>
    import('./AgencyRealEstateSearchMap').then(
      (mod) => mod.AgencyRealEstateSearchMap
    ),
  { ssr: false }
);

// Refs doesn't work with dynamically imported components:
// https://github.com/vercel/next.js/issues/4957#issuecomment-783482041
export function AgencyRealEstateSearchMap(
  props: AgencyRealEstateSearchMapProps
) {
  return <DynamicAgencyRealEstateSearchMap {...props} />;
}

AgencyRealEstateSearchMap.displayName = 'AgencyRealEstateSearchMap';
