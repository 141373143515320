import { clsx } from 'clsx';

import { BreadcrumbLink } from './BreadcrumbLink';

import css from './styles.module.scss';

enum Styles {
  breadcrumbs = 'in-breadcrumbsLite',
  breadcrumbsItem = 'in-breadcrumbsLite__item',
}
interface BreadcrumbsLiteProps {
  breadcrumbs: {
    label: string;
    link?: string;
    serverSideNavigation?: boolean;
  }[];
}

/**
 * Lite version of the Breadcrumb component without the dropdown menu and the SEO
 * microdata.
 */
export function BreadcrumbsLite({ breadcrumbs }: BreadcrumbsLiteProps) {
  return (
    <ul className={clsx(css[Styles.breadcrumbs], 'nd-list', 'nd-list--arrow')}>
      {breadcrumbs.map((b) => (
        <li
          key={b.label}
          className={clsx(css[Styles.breadcrumbsItem], 'nd-list__item')}
        >
          {b.link ? (
            <BreadcrumbLink
              link={b.link}
              label={b.label}
              serverSideNavigation={b.serverSideNavigation}
            />
          ) : (
            b.label
          )}
        </li>
      ))}
    </ul>
  );
}
