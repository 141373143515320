import { type PropsWithChildren, useState } from 'react';
import { capitalize, useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import type { AgencyData } from 'src/repository/agency/types';

import { CoverPageMap } from 'src/components/CoverPage/CoverPageMap';
import { CoverTitle } from 'src/components/CoverPage/CoverTitle';
import { LocationLink } from 'src/components/CoverPage/LocationLink';

import type { ProductConfig } from 'src/config/product';
import { getProductConfig } from 'src/config/product';

import { TagList } from '../TagList';

import css from './styles.module.scss';

enum Styles {
  overview = 'ag-agencyOverview',
  main = 'ag-agencyOverview__main',
  mainTop = 'ag-agencyOverview__mainTop',
  infoRow = 'ag-agencyOverview__infoRow',
  tagList = 'ag-agencyOverview__tagList',
}

interface AgencyOverviewProps {
  data: AgencyData;
}

export function AgencyOverview({
  data,
  children,
}: PropsWithChildren<AgencyOverviewProps>) {
  const { trans } = useTranslations();
  /* Set initialValue true on mobile during load to avoid CLS */
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm', initialValue: true });

  const [openMapDialog, setOpenMapDialog] = useState(false);

  const {
    address,
    name,
    timeContract,
    partnership,
    realEstateAds,
    realEstateSales,
    website,
    location: { latitude, longitude },
    image,
  } = data;

  const domainName: ProductConfig['domainName'] =
    getProductConfig('domainName');

  const handleLocationClick = () => {
    setOpenMapDialog(true);
  };

  return (
    <section className={css[Styles.overview]}>
      <div className={css[Styles.main]}>
        <div className={css[Styles.mainTop]}>
          <CoverTitle title={name} />
          {Boolean(partnership || timeContract) && (
            <div className={css[Styles.infoRow]}>
              {partnership && (
                <span>
                  {`${trans('lbl_associated', {
                    capitalize: true,
                  })} ${partnership}`}
                </span>
              )}

              {Boolean(timeContract) && (
                <span>
                  {trans('lbl_on_site_since_year', {
                    capitalize: true,
                    params: [capitalize(domainName), timeContract],
                    count: timeContract,
                  })}
                </span>
              )}
            </div>
          )}

          {maxScreenWidthSm && (
            <TagList
              customClass={css[Styles.tagList]}
              realEstateAds={realEstateAds}
              realEstateSales={realEstateSales}
            />
          )}
        </div>
        {openMapDialog &&
          address &&
          Boolean(latitude) &&
          Boolean(longitude) && (
            <CoverPageMap
              latitude={latitude}
              longitude={longitude}
              agencyLogo={image}
              address={address}
              title={trans('position_agency', { params: [name] })}
              onClose={() => setOpenMapDialog(false)}
            />
          )}
        <LocationLink
          website={website}
          address={address}
          latitude={latitude}
          longitude={longitude}
          onLocationClick={handleLocationClick}
        />
      </div>

      {children}
    </section>
  );
}
