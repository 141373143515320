import { getProductConfig } from 'src/config/product';

import type { ListDetail } from 'src/types/real-estate-list';
import type { RealEstateUserPreferences } from 'src/types/user-preferences';

import { findRealEstateByKey } from 'src/utils/real-estate';

export const getRealEstateListMaxPages = (totalAds: number) => {
  const maxPages =
    getProductConfig('listResultMaxLabel') /
    getProductConfig('listMaxResultsPerPage');

  const pages = Math.ceil(totalAds / getProductConfig('mapMaxResultsPerPage'));

  if (pages > maxPages) {
    return maxPages;
  }

  return pages;
};

export const getRealEstateListSaved = (
  listing: ListDetail[],
  preferences: RealEstateUserPreferences[]
) => {
  return listing.map((item) => {
    const preference = findRealEstateByKey(item.realEstate, preferences);

    if (!preference) {
      return item;
    }

    return {
      ...item,
      realEstate: {
        ...item.realEstate,
        saved: preference.saved,
      },
    };
  });
};
