import { useTranslations } from '@pepita-react/i18n';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { CONTRACT } from 'src/constants/typologies';

import type { PepitaSelectProps } from 'src/libs/ui/pepita-select';

type ContractOption = Exclude<PepitaSelectProps['value'], undefined> & {
  count?: number;
};

export const useContractOptions = () => {
  const { trans } = useTranslations();

  const contractOptions: ContractOption[] = [
    {
      label: 'lbl_for_sale', // i18n-extract-keys ["lbl_for_sale"]
      value: CONTRACT.VENDITA,
    },
    {
      label: 'lbl_for_rent', // i18n-extract-keys ["lbl_for_rent"]
      value: CONTRACT.AFFITTO,
    },
    {
      label: 'lbl_for_auction', // i18n-extract-keys ["lbl_for_auction"]
      value: CONTRACT.ASTE,
    },
    {
      label: 'lbl_single_sold_or_rented', // i18n-extract-keys ["lbl_single_sold_or_rented"]
      count: 2,
      value: CONTRACT.VENDUTO_AFFITTATO,
    },
    {
      label: 'lbl_all_ads', // i18n-extract-keys ["lbl_all_ads"]
      value: CONTRACT.ALL,
    },
  ];

  const translatedContractOptions = contractOptions
    .filter((opt) => {
      if (
        opt.value === CONTRACT.ASTE &&
        !isFeatureEnabled('AUCTIONS_ENABLED')
      ) {
        return false;
      }

      return true;
    })
    .map((opt) => {
      return {
        ...opt,
        // i18n-extract-disable-next-line
        label: trans(opt.label, {
          capitalize: true,
          ...(opt.count && { count: opt.count }),
        }),
      };
    });

  return translatedContractOptions;
};
