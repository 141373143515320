import type { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';

import { showStickyHeaderAtom } from '../atoms/stickyHeader';

import css from './style.module.scss';

interface CoverPageStickyHeaderWrapperProps {
  customClass?: string;
}

export function CoverPageStickyHeaderWrapper({
  children,
  customClass,
}: PropsWithChildren<CoverPageStickyHeaderWrapperProps>) {
  const showStickyHeader = useAtomValue(showStickyHeaderAtom);

  return (
    <div
      className={clsx(
        css['in-coverStickyHeader'],
        showStickyHeader && css['in-coverStickyHeader--fixed'],
        customClass
      )}
    >
      {children}
    </div>
  );
}
