import type { PropsWithChildren, ReactElement } from 'react';
import React from 'react';
import { clsx } from 'clsx';

import css from './styles.module.scss';

export type FormBarProps = {
  customClass?: string;
  navigationSection?: ReactElement | null;
  asideCustomClass?: string;
};

export function FormBar({
  customClass,
  navigationSection,
  asideCustomClass,
  children,
}: PropsWithChildren<FormBarProps>) {
  return (
    <>
      <div className={clsx(css['in-formBar'], customClass)}>
        {children}
        {navigationSection && (
          <div className={clsx(css['in-formBar__aside'], asideCustomClass)}>
            {navigationSection}
          </div>
        )}
      </div>
    </>
  );
}
