import { useCallback, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import type { AgencyData } from 'src/repository/agency/types';

import { ContactFormDialog } from 'src/components/ContactForm';
import { ContactsWrapper } from 'src/components/CoverPage/ContactsWrapper';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import {
  PurchaseAndContactEventValue,
  TrackHandleContactsClickType,
} from 'src/tracking/utils/enums';

import { useAgencyTracking } from 'src/views/Agency/hooks/useAgencyTracking';

import { Phone } from '../Phone';

interface AgencyContactsProps {
  data: AgencyData;
  isHorizontal?: boolean;
  viewInModal?: boolean;
}

export function AgencyContacts({
  data,
  isHorizontal,
  viewInModal = false,
}: AgencyContactsProps) {
  const { trans } = useTranslations();
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const { id, type, name, image, phones, website, isPaid, customAgencyType } =
    data;
  const hasMoreThanOnePhone = phones.length > 1;
  const { trackHandleContactsClick, trackAgencyContactSubmit } =
    useAgencyTracking();

  const onContactClick = useCallback(() => {
    setIsContactDialogOpen(true);
    trackHandleContactsClick(TrackHandleContactsClickType.MESSAGE);
  }, [trackHandleContactsClick]);

  return (
    <ContactsWrapper isHorizontal={isHorizontal}>
      {isContactDialogOpen && (
        <ContactFormDialog
          agency={{
            id,
            type,
            displayName: name,
            imageUrls: { large: image },
            phones,
            externalLinks: {
              url: website || '',
              title: '',
            },
            guaranteed: false,
            isPaid,
            customType: customAgencyType,
          }}
          onClose={() => setIsContactDialogOpen(false)}
          title={trans('act_ask_informations', {
            capitalize: true,
          })}
          onShowPhoneClick={(phoneType) => {
            trackAgencyContactSubmit({
              purchaseAndContactEventValue: PurchaseAndContactEventValue.PHONE,
              isVirtualPhone: phoneType === 'vtel',
            });
          }}
          onSubmitContact={(contactData) =>
            trackAgencyContactSubmit({
              purchaseAndContactEventValue: PurchaseAndContactEventValue.EMAIL,
              isVirtualPhone: false,
              contactData,
            })
          }
          textareaPlaceholder={trans('contact_form_message_placeholder', {
            capitalize: true,
          })}
        />
      )}
      <PepitaButton
        variant="accent"
        block={!maxScreenWidthSm && !isHorizontal}
        icon={maxScreenWidthSm ? { name: 'chat' } : undefined}
        onClick={onContactClick}
        iconOnly={hasMoreThanOnePhone && maxScreenWidthSm}
      >
        {trans('lbl_message')}
      </PepitaButton>
      {phones.map((p) => (
        <Phone
          key={p.value}
          number={p.value}
          type={p.type}
          block={!maxScreenWidthSm && !isHorizontal}
          onShowPhoneClick={() => {
            trackAgencyContactSubmit({
              purchaseAndContactEventValue: PurchaseAndContactEventValue.PHONE,
              isVirtualPhone: p.isVirtual,
            });
            trackHandleContactsClick(
              TrackHandleContactsClickType.TELEPHONE,
              phones[0].type === 'green-phone'
            );
          }}
          viewInModal={viewInModal && maxScreenWidthSm}
        />
      ))}
    </ContactsWrapper>
  );
}
