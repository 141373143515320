import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';
import type { DayHours } from 'src/repository/agency/types';

import { CoverTitle } from 'src/components/CoverPage/CoverTitle';

import { PepitaList, PepitaListItem } from 'src/libs/ui/pepita-list';

import css from './styles.module.scss';

const sortDayOfTheWeek = (
  openingHours: DayHours[],
  startingDay: number
): DayHours[] => {
  const delta = 7 - startingDay;

  return openingHours
    .slice()
    .sort((a, b) => ((a.day + delta) % 7) - ((b.day + delta) % 7));
};

interface HoursProps {
  agencyName: string;
  openingHours: DayHours[];
}

export function Hours({ agencyName, openingHours }: HoursProps) {
  const { trans } = useTranslations();
  const dayOfTheWeek = new Date().getDay();

  return (
    <>
      <CoverTitle title={agencyName} tag="p" />
      {sortDayOfTheWeek(openingHours, dayOfTheWeek).map((item) => {
        // i18n-extract-disable-next-line
        const dayName = trans(`week_day_${item.day}`, {
          capitalize: true,
        });

        return (
          <div
            key={dayName}
            className={clsx(
              css['ag-hours__dayHours'],
              dayOfTheWeek === item.day && 'is-today'
            )}
          >
            <span className={css['ag-hours__title']}>
              {dayOfTheWeek === item.day
                ? trans('lbl_today', {
                    capitalize: true,
                  })
                : dayName}
            </span>
            {item.hours.length ? (
              <PepitaList type="inline" customClass={css['ag-hours__list']}>
                {item.hours.map((el, index) => (
                  <PepitaListItem
                    key={`${el.join('-')}-${index}`}
                    customClass={css['ag-hours__listItem']}
                  >
                    {`${el.join(' - ')}`}
                  </PepitaListItem>
                ))}
              </PepitaList>
            ) : (
              <span className={css['ag-hours__listItem']}>
                {trans('lbl_closed', {
                  capitalize: true,
                })}
              </span>
            )}
          </div>
        );
      })}
    </>
  );
}
