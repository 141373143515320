import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { searchFormStateAtom } from '../atoms/queryParamsStateAtom';

import { useQueryWithInstantInvalidation } from 'src/hooks/useQueryWithInstantInvalidation';
import { useAgencyQueryParams } from './useAgencyQueryParams';

import { http } from 'src/utils/client/http';

export const useSearchFormCount = () => {
  const { id, pageType } = useAgencyQueryParams();
  const searchFormState = useAtomicStateAtomValue(searchFormStateAtom);

  const { data } = useQueryWithInstantInvalidation<number>({
    queryKey: ['results-count', searchFormState, id, pageType],
    queryFn: () =>
      http
        .get(`/api-next/agency/${id}/count/`, {
          searchParams: { ...searchFormState, type: pageType },
        })
        .json(),
  });

  return {
    count: data,
  };
};
