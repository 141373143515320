import { useRef } from 'react';
import { useRouter } from 'next/router';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { queryParamsStateAtom } from '../atoms/queryParamsStateAtom';

import { useDebouncedEffect } from 'src/hooks/useDebouncedEffect';

import { getPathFromUrl, serializeIntoUrl } from 'src/utils/querystring';

export const useUpdateQueryParams = () => {
  const router = useRouter();
  const firstRender = useRef(true);
  const queryParamsState = useAtomicStateAtomValue(queryParamsStateAtom);

  useDebouncedEffect(
    () => {
      if (firstRender.current) {
        firstRender.current = false;

        return;
      }

      if (Object.keys(queryParamsState).length === 0) {
        return;
      }

      const { sortBy, sortDir, ...queryParams } = queryParamsState;

      const url = serializeIntoUrl(getPathFromUrl(router.asPath), {
        ...queryParams,
        sortBy,
        sortDir,
      });

      router.push(url, undefined, { shallow: true });
    },
    [queryParamsState],
    500
  );
};
