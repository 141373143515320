import { useBreakpoint } from '@pepita-react/use-breakpoint';

import { Filters } from '../Filters';
import { MobileFilters } from '../Filters/MobileFilters';

export function AgencyFormBar() {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  if (maxScreenWidthSm) {
    return <MobileFilters />;
  }

  return <Filters />;
}
