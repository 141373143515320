import type { Language } from 'src/types/translations';

import { dateFormatter, type Trans } from 'src/utils/i18nFormatters';

const DIFF_DAYS_LIMIT = 6;

const getDiffDays = (contactedDate: Date) => {
  const contactedDay = contactedDate.getDate();

  const now = new Date();
  const diffDays = now.getDate() - contactedDay;
  const diffMonths = now.getMonth() - contactedDate.getMonth();
  const diffYears = now.getFullYear() - contactedDate.getFullYear();

  // The event happened the same month, same year.
  if (diffYears === 0 && diffMonths === 0) return diffDays;

  // The event happened the previous month.
  if (
    // Same year, previous month
    (diffYears === 0 && diffMonths === 1) ||
    // Previous year, previous month, December -> January
    (diffYears === 1 && diffMonths === -11)
  ) {
    const contactedMonthNoOfDays = new Date(
      contactedDate.getFullYear(),
      contactedDate.getMonth() + 1,
      0
    ).getDate();

    const daysTillTheEndOfTheLastMonth = contactedMonthNoOfDays - contactedDay;
    const daysFromTheStartOfThisMonth = now.getDate();
    const diff = daysTillTheEndOfTheLastMonth + daysFromTheStartOfThisMonth;

    return diff;
  }

  // We don't need to compute the real value of diff days if it exceeds
  // the limit.
  return DIFF_DAYS_LIMIT + 1;
};

export const getContactedMessage = (
  contactedAt: string,
  language: Language,
  trans: Trans
) => {
  const contactedDate = new Date(contactedAt);
  const diff = getDiffDays(contactedDate);

  if (diff >= 0 && diff <= DIFF_DAYS_LIMIT) {
    if (diff === 0) return trans('msg_sent_today');
    if (diff === 1) return trans('msg_sent_yesterday');
    if (diff === 2) return trans('msg_sent_before_yesterday');

    return trans('msg_sent_days_ago', { params: [diff] });
  }

  const date = dateFormatter(contactedDate.getTime() / 1000, language, {
    withoutYear: new Date().getFullYear() === contactedDate.getFullYear(),
  });

  return trans('msg_sent_date', date ? { params: [date] } : undefined);
};
