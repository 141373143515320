import { useBreakpoint } from '@pepita-react/use-breakpoint';
import type { AgencyPhone } from 'src/repository/agency/types/formatted-data-types';

import { ContactsWrapper } from 'src/components/CoverPage/ContactsWrapper';

import {
  PurchaseAndContactEventValue,
  TrackHandleContactsClickType,
} from 'src/tracking/utils/enums';

import { useAgencyTracking } from 'src/views/Agency/hooks/useAgencyTracking';

import { Phone } from '../Phone';

interface FranchisingContactProps {
  viewInModal?: boolean;
  phones: AgencyPhone[];
}

export function FranchisingContact({
  viewInModal = false,
  phones,
}: FranchisingContactProps) {
  const { trackHandleContactsClick, trackAgencyContactSubmit } =
    useAgencyTracking();
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const phoneGroup = phones.length > 0 ? phones[0] : undefined;

  if (!phoneGroup) {
    return null;
  }

  return (
    <ContactsWrapper>
      <Phone
        number={phoneGroup.value}
        type={phoneGroup.type}
        block
        onShowPhoneClick={() => {
          trackAgencyContactSubmit({
            purchaseAndContactEventValue: PurchaseAndContactEventValue.PHONE,
            isVirtualPhone: phoneGroup.isVirtual,
          });
          trackHandleContactsClick(
            TrackHandleContactsClickType.TELEPHONE,
            phoneGroup.type === 'green-phone'
          );
        }}
        viewInModal={viewInModal && maxScreenWidthSm}
      />
    </ContactsWrapper>
  );
}
