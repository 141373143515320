import type { CATEGORIES, TYPOLOGIES } from 'src/constants/typologies';
import { CONTRACT } from 'src/constants/typologies';

import type { CategoryConfigType } from 'src/utils/categoriesConfig';
import { getCategoriesConfig } from 'src/utils/categoriesConfig';
import { getListingTypologies } from 'src/utils/getTypologies';

export const getOptions = (
  categoriesConfig: CategoryConfigType[],
  contract: CONTRACT[]
) => {
  const configs = categoriesConfig
    .filter((categoryConfig) =>
      categoryConfig.contracts.some((item) => contract.includes(item))
    )
    .map((config) => ({
      selected: false,
      label: config.label,
      value: config.value,
      children: config.children
        ? config.children.map((child) => ({
            selected: false,
            label: child.label,
            value: `${child.categoryId}_${child.typologyId}`,
            children: [],
          }))
        : [],
    }));

  configs.forEach((config) => {
    if (config.children.length > 0) {
      config.children.unshift({
        selected: false,
        label: 'lbl_all_typologies', // i18n-extract-keys ["lbl_all_typologies"]
        value: config.value,
        children: [],
      });
    }
  });

  return configs;
};

export const getDefaultValue = (
  categoryId?: CATEGORIES,
  typeId?: TYPOLOGIES[]
): string | undefined => {
  if (!categoryId && !typeId) {
    return;
  }

  if (!typeId || !typeId.length) {
    return `${categoryId}_`;
  }

  return typeId.map((id) => `${categoryId}_${id}`).join(',');
};

export const isAllowedCategory = (
  contractId: CONTRACT,
  categoryId?: CATEGORIES
) => {
  const contracts =
    contractId === CONTRACT.ALL
      ? [CONTRACT.VENDITA, CONTRACT.AFFITTO]
      : [contractId];

  const categoryConfig = getCategoriesConfig(getListingTypologies());
  const filteredCategories = categoryConfig.filter((categoryConfig) =>
    categoryConfig.contracts.some((item) => contracts.includes(item))
  );

  return filteredCategories.some((el) => el.categoryId === categoryId);
};
