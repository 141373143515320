import { useTranslations } from '@pepita-react/i18n';
import type { AgencyData } from 'src/repository/agency/types';

import { CoverTitle } from 'src/components/CoverPage/CoverTitle';
import { ReadAll } from 'src/components/ReadAll';
import { Tabs } from 'src/components/Tabs';
import { Tab } from 'src/components/Tabs/Tab';
import { TabList } from 'src/components/Tabs/TabList';
import { TabPanel } from 'src/components/Tabs/TabPanel';
import { TabPanels } from 'src/components/Tabs/TabPanels';

import { useAgencyTracking } from 'src/views/Agency/hooks/useAgencyTracking';

import { AgencyContacts } from '../AgencyContacts';
import { AgencyOverview } from '../AgencyOverview';
import { Hours } from '../Hours';
import { Team } from '../Team';

interface AgencyTabsProps {
  data: AgencyData;
}

export function AgencyTabs({ data }: AgencyTabsProps) {
  const { trans } = useTranslations();
  const { name, agents, description, openingHours } = data;
  const { trackHandleSwitchTab } = useAgencyTracking();

  return (
    <Tabs
      onChange={(_, selectedTab) => {
        trackHandleSwitchTab(selectedTab);
      }}
      currentIndex={0}
    >
      <TabList>
        <Tab name="overview">{trans('lbl_agency_overview')}</Tab>

        {description && (
          <Tab name="about">{trans('ftr_anchor_who_we_are')}</Tab>
        )}

        {agents.length > 0 && (
          <Tab name="team">{trans('lbl_real_estate_agents')}</Tab>
        )}

        {openingHours.length > 0 && (
          <Tab name="hours">{trans('lbl_times')}</Tab>
        )}
      </TabList>

      <TabPanels>
        <TabPanel>
          <AgencyOverview data={data}>
            <AgencyContacts viewInModal data={data} />
          </AgencyOverview>
        </TabPanel>

        {description && (
          <TabPanel forceUpdate={true}>
            <CoverTitle title={name} tag="p" />
            <ReadAll enableToggle={true}>
              <div
                dangerouslySetInnerHTML={{
                  __html: description.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                }}
              />
            </ReadAll>
          </TabPanel>
        )}

        {agents.length > 0 && (
          <TabPanel>
            <Team agencyName={name} agents={agents} />
          </TabPanel>
        )}

        {openingHours.length > 0 && (
          <TabPanel forceUpdate={true}>
            <Hours agencyName={name} openingHours={openingHours} />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}
