import type { PropsWithChildren } from 'react';
import clsx from 'clsx';

import css from './styles.module.scss';

interface TabProps {
  index?: number;
  selected?: boolean;
  onSelect?: () => void;
  name: string;
}

export function Tab({
  name,
  index,
  children,
  selected = false,
  onSelect,
}: PropsWithChildren<TabProps>) {
  return (
    <button
      name={name}
      type="button"
      role="tab"
      id={`tab-${index}`}
      aria-controls={`tabpanel-${index}`}
      tabIndex={selected ? 0 : -1}
      aria-selected={selected}
      onClick={onSelect}
      className={clsx('nd-tab', css['in-tab'], selected && 'nd-tab--active')}
    >
      {children}
    </button>
  );
}
