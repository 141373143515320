import { useCallback } from 'react';
import { useIsOnClient } from '@indomita-react/custom-hooks';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { useAtom } from 'jotai';

import { useAtomicStateStateAtom } from 'src/atoms/atomic-state';

import { ToggleMapListButton } from 'src/components/ToggleMapListButton';

import type {
  CATEGORIES,
  CONTRACT,
  TYPOLOGIES,
} from 'src/constants/typologies';

import { showMapAtom } from 'src/entities/search/components/SearchLayout';

import { queryParamsStateAtom } from 'src/views/Agency/atoms/queryParamsStateAtom';

import { ContractSelect } from './ContractSelect';
import { FiltersSkeleton } from './FiltersSkeleton';
import { TypologySelect } from './TypologySelect';
import { isAllowedCategory } from './TypologySelect/utils';

import css from './styles.module.scss';

export function Filters() {
  const isOnClient = useIsOnClient();
  const [maxScreenWidthLg] = useBreakpoint({ to: 'lg' });
  const [queryParamsState, setQueryParamsState] =
    useAtomicStateStateAtom(queryParamsStateAtom);
  const [showMap, setShowMap] = useAtom(showMapAtom);

  const handleContractSelect = useCallback(
    (contractId: CONTRACT) => {
      setQueryParamsState((prevState) => {
        if (isAllowedCategory(contractId, prevState.categoryId)) {
          return {
            ...prevState,
            contractId,
          };
        }

        return {
          ...prevState,
          contractId,
          categoryId: undefined,
          typeId: undefined,
          pag: undefined,
        };
      });
    },
    [setQueryParamsState]
  );

  const handleTypologySelect = useCallback(
    (categoryId: CATEGORIES | undefined, typeId: TYPOLOGIES[] | undefined) => {
      setQueryParamsState((prevState) => ({
        ...prevState,
        categoryId,
        typeId,
        pag: undefined,
      }));
    },
    [setQueryParamsState]
  );

  if (!isOnClient) {
    return <FiltersSkeleton />;
  }

  return (
    <div className={css['ag-formBar']}>
      <div className={css['ag-formBar__filters']}>
        <ContractSelect
          contractId={queryParamsState.contractId}
          onChange={handleContractSelect}
        />
        <TypologySelect
          contractId={queryParamsState.contractId}
          categoryId={queryParamsState.categoryId}
          typeId={queryParamsState.typeId}
          onChange={handleTypologySelect}
        />
      </div>

      {maxScreenWidthLg && (
        <ToggleMapListButton
          onToggle={(s) => setShowMap(s === 'list' ? true : false)}
          initialState={showMap ? 'list' : 'map'}
        />
      )}
    </div>
  );
}
